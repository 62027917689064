.context-menu {
  position: absolute;
  z-index: 1000;
  border: solid 1px #1a192b;
  background-color: #f6f6f6;
  padding: 5;
  display: flex;
  flex-direction: column;
  margin: 0;
  box-shadow: 0 0 20px 0 #ccc;
}

.context-menu button {
  background-color: #f6f6f6;
  border: 0;
  color: #2a2a2a;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 215px;
  padding-top: 2px;
  padding-bottom: 2px;
  transition-duration: 0.5s;
}

.context-menu button:hover {
  background-color: #e0dfdf;
  border-left: solid 5px #cecece;
  padding-left: 5px;
}

.context-menu-icon {
  margin-right: 10px;
  color: #2a2a2a;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

.refresh-start {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}