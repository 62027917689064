@import "../variables.scss";

@import "blocks/spg-action-bar.scss";
@import "blocks/spg-question.scss";
@import "blocks/spg-input.scss";
@import "blocks/spg-dropdown.scss";
@import "blocks/spg-comment.scss";
@import "blocks/spg-panel.scss";
@import "blocks/spg-checkbox.scss";
@import "blocks/spg-matrixdynamic.scss";
@import "blocks/spg-table.scss";

.spg-root-modern {
  width: 100%;
  border-right: 1px solid $border;
  box-sizing: border-box;
}

.sv-popup--modal {
  &.sv-property-editor .sv-popup__container {
    background-color: $background-for-editors;

    .sv-popup_shadow {
      background-color: transparent;
    }
    .sd-root-modern {
      background-color: transparent;
    }
    .sd-page {
      margin: 0;
      padding: 0;
    }
  }
  .sv-popup__footer {
    padding-bottom: 2px;
  }
  padding: calcSize(4);
}
.sv-popup.sv-property-editor .sd-root-modern{
  --sd-mobile-width: 0; 
}
.sv-popup__container {
  margin-left: calcSize(1);
  margin-right: calcSize(1);
}

.spg-title {
  .spg-action-bar {
    margin-top: calcSize(-1);
    margin-bottom: calcSize(-1);
    margin-right: 1px;
  }
}

.spg-body--empty {
  margin: calcSize(3);
}