@import "../../variables.scss";

.svc-logo-image {
  position: relative;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 0;
  .svc-image-item-value-controls {
    display: block;
    top: calcSize(2.5);
    right: calcSize(1.5);
  }
}

.svc-logo-image-placeholder {
  font-size: calcSize(4);
  color: $foreground-light;
  opacity: 0.25;
  cursor: pointer;
  white-space: nowrap;
  word-break: keep-all;
  padding: 0;
  width: calcSize(4);
  height: calcSize(4);
  svg {
    width: calcSize(4);
    height: calcSize(4);
  }
}

.svc-logo-image-placeholder use {
  fill: $foreground-light;
}
