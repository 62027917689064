.spg-matrixdynamic__drag-element {
  padding: calcSize(2);
  &:hover {
    background-color: $background;
  }
  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
  }
}

.spg-drag-element__svg {
    width: calcSize(2);
    height: calcSize(2);
    padding: calcSize(2);
    display: block;
    opacity: 0.5;
  
    use {
      fill: $foreground-light;
    }
}

.spg-matrixdynamic__drag-drop-ghost-position-top,
.spg-matrixdynamic__drag-drop-ghost-position-bottom {
  position: relative;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after,
.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  content: "";
  width: 100%;
  height: calcSize(0.5);
  background-color: $secondary;
  position: absolute;
  left: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after {
  top: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  bottom: 0;
}
.spg-matrixdynamic__placeholder { 
  padding: calcSize(6) 0;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  background: $background;
  border: 1px solid $border;
  box-sizing: border-box;
  .spg-matrixdynamic__add-btn {
    display: initial;
  }
}
.spg-matrixdynamic__placeholder-text {
  font-size: calcSize(2);
  line-height: calcSize(3);
  color: $foreground-light;
}
.spg-matrixdynamic__add-btn {
  display: none;
  appearance: none;
  border: none;
  margin-top: calcSize(0.5);
  background: transparent;
  color: $primary;
  font-family: $font-family;
  font-size: calcSize(2);
  font-weight: 600;
  line-height: calcSize(3); 
}