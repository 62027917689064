.spg-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}
.spg-question__header {
  width: 100%;
}
.spg-question__title {
  width: 100%;
  margin: 0;
  padding: calcSize(1) 0;
  line-height: calcSize(3);
  font-size: calcSize(2);
  font-family: $font-family;
  color: $foreground-light;
  font-weight: normal;
  box-sizing: border-box;
}

.spg-question__content {
  width: 100%;
  color: $primary;
  cursor: pointer;
}

.spg-question__description {
  line-height: calcSize(3);
  font-size: calcSize(2);
  color: $foreground;
  padding-bottom: calcSize(2);
}
.spg-row-narrow__question {
  margin-top: calcSize(-2);
}
.spg-question--location--left {
  flex-direction: row;
  background: $background;
  border: 1px solid $border;
  line-height: calcSize(6);
  vertical-align: middle;
  align-items: stretch;

  &:focus-within {
      border-color: $primary;
      box-shadow: none;

      .spg-question__header--location--left {
        box-shadow: none;
        border: 1px solid $primary;
        border-left: none;
        border-right: none;
        box-shadow: inset 1px 0 0 0 $primary;
      }
  }
  .spg-input:focus {
    border: 1px solid $primary;
    border-left: none;
    border-right: none;
    box-shadow: inset -1px 0 0 0 $primary;
  }
}

.spg-question__header--location--left {
  width: auto;
  max-width: 50%;
  .spg-question__title {
    padding: calcSize(1) calcSize(2);
    border-right: 1px solid $border;
    display: inline-block;
  }
  display: flex;
  align-items: center;
  
}

.spg-question__content--left {
  .spg-input {
    border: none;
  }
}
.spg-row--multiple {
  display: flex;
  gap: calcSize(1);
  overflow: hidden;
  flex-wrap: wrap;

  & > div {
    width: 0;
    flex-grow: 1;
    min-width: calcSize(25.5);
    .spg-question__header--location--left {
      min-width: calcSize(8);
    }
    input {
      min-width: calcSize(17);
      width: 100%;
    }
    input[type="date"] {
      &::-webkit-calendar-picker-indicator {
        display: none;
      }
      width: 100%;
    }
  }
}
