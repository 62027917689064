@import "../variables.scss";

.svc-row {
  width: 100%;
}
.svc-row .sd-row--multiple {
  padding: calcSize(1);
  gap: calcSize(1);
}
.sd-panel .svc-row .sd-row--multiple {
  padding: calcSize(0);
  box-shadow: none;
  border-radius: 0;
}

.svc-row.svc-row--ghost {
  .svc-question__content {
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .sd-row {
    margin-top: calcSize(2);
    margin-bottom: calcSize(2);
  }
}
